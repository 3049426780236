body {
  background-color: #202020;
}

#container {
}

.App {
  text-align: center;
}

.button-image {
  display: inline !important;
}
